import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
        social: { twitter: string }
      }
    }
  }
}

const ArchivePage = ({ data }: Props) => {
  const {
    title: siteTitle,
    social: { twitter },
  } = data.site.siteMetadata
  return (
    <Layout title={siteTitle} twitter={twitter}>
      <section className="markdown pb-12">
        Please delete mysite from archive.
      </section>
    </Layout>
  )
}

export default ArchivePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
  }
`
